@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

.custom-responsive {
  @apply text-base sm:text-lg md:text-xl lg:text-xl font-bold text-custom-blue-light;
}


input::placeholder {
  color: black;
}

.ag-theme-quartz{
  overflow: auto;
}

.ag-header-cell-label {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}


.ag-theme-quartz{
  --ag-header-cell-hover-background-color: rgb(80, 40, 140);
}

.ag-theme-quartz .ag-paging-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-input-placeholder{
  color: #666;
}

input[type="search"]:focus {
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 4px center;

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="number"] {
    -moz-appearance: textfield;
} */

/* CSS styles for status column */
.bg-active {
  background-color: green;
  color: white;
}

.bg-inactive {
  background-color: red;
  color: white;
}

.bg-pending {
  background-color: yellow;
  color: black;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.input-style::placeholder {
  color: #666; /* Change placeholder color */
  opacity: 1; /* Ensure it is fully visible */
}

/* Center Align Header */
.ag-header-cell {
  text-align: center !important;
}

/* Center Align Cell Content */
.ag-cell {
  text-align: center !important;
}

/* Optional: If you want to target only specific columns, you can add specific classes */
.ag-header-cell-center, .ag-cell-center {
  text-align: center !important;
}
