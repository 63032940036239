.table-bordered {
    border: 1px solid lightgray; /* Table border */
  }
  
  .table-bordered th, .table-bordered td {
    /* border: 1px solid #ddd;  */
    padding: 8px; /* Optional: Adds padding for better spacing */
  }
  
  .table-bordered th {
    background-color:#F0F9FF;
  }