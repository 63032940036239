.skeleton-container {
    padding: 20px;
  }
  
  .skeleton-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .skeleton-box {
    width: 30%;
    height: 100px;
    background-color: #ececec;
    border-radius: 8px;
    animation: skeleton-animation 500ms infinite ease-in-out;
  }
  
  .skeleton-line {
    width: 100%;
    height: 20px;
    background-color: #ececec;
    margin-bottom: 10px;
    animation: skeleton-animation 500ms infinite ease-in-out;
  }
  
  .skeleton-line.large {
    width: 80%;
  }
  
  .skeleton-circle {
    width: 25px;
    height: 25px;
    background-color: #ececec;
    border-radius: 50%;
    margin-right: 10px;
    animation: skeleton-animation 500ms infinite ease-in-out;
  }
  
  .skeleton-divider {
    width: 100%;
    height: 1px;
    background-color: #ececec;
    margin: 10px 0;
    animation: skeleton-animation 500ms infinite ease-in-out;
  }
  
  @keyframes skeleton-animation {
    0% {
      background-color: #ececec;
    }
    50% {
      background-color: #f3f3f3;
    }
    100% {
      background-color: #ececec;
    }
  }
  