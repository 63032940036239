.box{
    width: 400px;
    height: 350px;
    margin-left: 30px;
    border: 1px solid #026AA2;
    padding: 20px 40px;
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    transition: box-shadow 0.3s ease;
    background-color: #f5f5f5;
    color: #026AA2;             
    border-radius: 10px;
}
.boxHoverDiv{
    position: absolute;
    inset: 0;
    /* background-color: rgba(255, 255, 255, 0.7); */
    border-radius: 10px;
    backdrop-filter: blur(8px); 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition:background-color 0.3s ease, backdrop-filter 0.3s ease;
}
.boxHoverButton{
    /* background-color: #fe9719; */
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.img{
    /* margin-top: 10px; */
    display: flex;
    margin-left: -9px;
}
.divimg{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 10px;
    width: 25px;
    height: 25px;
    padding: 4px 3px;
    text-align: center;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 1px 4px 0px #00000040;

  
}
.fparent{
    display: flex;
    padding: 30px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.fchild1{
    display: flex;
    flex-direction: column;
    width: 35vw;
    border: .8px solid lightgrey;

    border-radius: 5px;

    
}
.fchild2{
    width: 35vw;
    border: .8px solid lightgrey;
 height: 50vh;
 border-radius: 5px;


}
.fhead{
 background-color: #E0F2FE;
 padding: 20px;
 height: 65px;
}
.inputfeilds{
    padding:20px 15px;
    display: flex;
    flex-direction: column;
    gap:10px;
    /* justify-content: center; */
    align-items: center;
    /* align-content: center; */

}

.inputs:hover{
    background-color: #E0F2FE;
}

.inputfeildsparent{
    display: flex;
    justify-content: space-evenly;
    padding: 20px 20px;

}

.inputfeildsfoot{
    display: flex;
    padding: 20px;
}
.inputfeildsfoot>div{
    border: .5px solid lightgrey;
    border-radius: 20px;
    width:125px;
    height: 30px;
    margin: 8px 10px;
    font-size:14px ;
}
.footericon{
    padding: 4px 8px;
    cursor:pointer;

}
.icon{
    margin-top: 3px;
    margin-right: 5px;
}
.boxtemp{
    width: 400px;
    height: 350px;
    margin-left: 30px;
    padding: 20px 40px;
    margin-right: 30px;
    margin-bottom: 30px;
}
.btns{
    width:70px;
    margin:8px 8px;
    padding: 5px 10px;
border: 1px solid #008C8C;
border-radius: 4px;
}
.btnfoot{
    text-align: end;
}
.inputfeilds1{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.inputfeilds2{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputfeild1{
    margin-left: 25px;
    }
    
.inputs{
    margin-top:10px ;
    margin-bottom: 10px;
    width:85%;
}