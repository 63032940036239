.jodit-toolbar-button-bold .jodit-icon {
    color: red;
  }
  
  .jodit-toolbar-button-italic .jodit-icon {
    color: green;
  }
  
  .jodit-toolbar-button-underline .jodit-icon {
    color: blue;
  }

  .jodit-toolbar-button-bold {
    background-color: yellow; /* Yellow background for bold button */
  }
  
  .jodit-toolbar-button-italic {
    background-color: lightgreen; /* Light green background for italic button */
  }
  
  .jodit-toolbar-button-underline {
    background-color: lightblue; /* Light blue background for underline button */
  }

  /* Customizing the colors of toolbar buttons in Jodit Editor */
.jodit-toolbar-button-bold .jodit-icon {
    color: red !important;
  }
  
  .jodit-toolbar-button-italic .jodit-icon {
    color: green !important;
  }
  
  .jodit-toolbar-button-underline .jodit-icon {
    color: blue !important;
  }
  
  /* Add more styles as needed */
  .jodit-toolbar-button-strikethrough .jodit-icon {
    color: purple !important;
  }
  
  .jodit-toolbar-button-ul .jodit-icon {
    color: orange !important;
  }
  
  /* And so on for other buttons */
  
  
  