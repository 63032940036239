.heading {
    width: 100%;
    border: 1px solid lightgray;
    background-color: #d5d9eb;
}

.data {
    width: 100%;
    border: 1px solid lightgray;
    margin-bottom: 10px;
    margin-top: 10px;

}

th {
    padding: 5px 10px;
    width: 400px;
    text-align: center;
}

td {
    width: 400px;
    padding: 5px 10px;
    text-align: center;


}

table tbody td select {
    width: 120px;
    border: 1px solid grey;
    border-radius: 3px;
    color: #039855;
    padding: 4px 8px;
    background: white;
    cursor: pointer;
}

table tbody td button {
    width: 120px;
    border: 1px solid black;
    border-radius: 3px;
    padding: 4px 8px;
    background-color: #d5d9eb;
    color: #054F31;
}